import React from 'react';

// === Components === //
import { Redirect } from '@reach/router';
import Page from '@components/layout/Page';
import Container from '@components/common/Container';
import HeroBanner from '@components/sections/HeroBanner';
import Paragraph from '@components/common/Paragraph';

// === Content === //
import { simplePageData } from '@graphql/SimplePage';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, fluidRange } from '@styles';

// === Types === //
import { PageType, Variant } from '@content/types/layout';
import { SimplePageData } from '@content/types/simplepage';

const PageBodyWrapper = styled.div`
  p {
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 20px;
    margin-bottom: 20px;
    color: ${colors.text.light};
  }

  h1,
  h2,
  h3,
  h4 {
    ${fluidRange('marginTop', '48px', '60px')}
    ${fluidRange('marginBottom', '20px', '30px')}
  }
`;

interface SimplePageProps {
  pageContext?: SimplePageContextData;
}

interface SimplePageContextData {
  slug: string;
  uuid: string;
  variant: Variant;
}

const SimplePage = ({ pageContext }: SimplePageProps) => {
  const pageType: PageType = 'basic_page';
  const pages: SimplePageData[] = simplePageData();
  const page: SimplePageData | undefined =
    pages.find((page) => page.uuid === pageContext?.uuid) || undefined;

  return page ? (
    <Page
      variant={pageContext?.variant || 'blue'}
      pageType={pageType}
      navUuid={page.uuid}
      bottomSpace={true}
      metaData={page.metaData}
      pageUrl={`/${pageContext?.slug || ''}`}
    >
      <>
        <HeroBanner
          image={page.heroBanner.image.src}
          image_mobile={page.heroBanner.imageMobile.src}
          text={page.heroBanner.text}
        />
        <Container>
          <PageBodyWrapper>
            {page.content.map((text, index) => (
              <Paragraph key={index} text={text} variant={pageContext?.variant || 'blue'} />
            ))}
          </PageBodyWrapper>
        </Container>
      </>
    </Page>
  ) : (
    <Redirect to="/404" />
  );
};

export default SimplePage;
