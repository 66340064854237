import get from 'lodash.get';

export const simplePageTransform = (query) =>  {
  const queryData = query.nodeQuery;

  const data = get(queryData, 'entities', []).map(entity => ({
    title: get(entity, 'entityLabel', ''),
    url: get(entity, 'entityUrl.path', ''),
    uuid: get(entity, 'uuid', ''),
    content: get(entity, 'body', []).map(body => get(body, 'processed', '')),
    metaData: {
      title: get(entity, 'fieldMetaTitle', ''),
      description: get(entity, 'fieldMetatags.entity.fieldDescription', ''),
      keywords: get(entity, 'fieldMetatags.entity.fieldKeywords', ''),
    },
    heroBanner: {
      image: {
        src: get(entity, 'fieldBannerTop.entity.fieldBannerImage.url', ''),
        alt: get(entity, 'fieldBannerTop.entity.fieldBannerImage.alt', ''),
        title: get(entity, 'fieldBannerTop.entity.fieldBannerImage.title', ''),
      },
      imageMobile: {
        src: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.url', ''),
        alt: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.alt', ''),
        title: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.title', ''),
      },
      text: get(entity, 'fieldBannerTop.entity.fieldBannerText.value', ''),
    },
  }))

  return data;
}